import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GatsbyImage from 'gatsby-image'

const BottomImage: React.FC = () => {
	const data = useStaticQuery(
		graphql`
			query {
				filename: file(
					relativePath: { eq: "tradetapp/account-migration/photo.png" }
				) {
					childImageSharp {
						fluid(maxWidth: 2000) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	)

	return (
		<GatsbyImage
			fluid={data.filename.childImageSharp.fluid}
			alt="tradetapp moving image"
		/>
	)
}

export default BottomImage
