import styled from 'styled-components'

import { tablet, mobile } from 'shared/media-queries'

export const Header = styled.div`
	font-weight: bold;
	font-size: 52px;
	line-height: 54px;
	text-align: center;
	color: white;
	padding-bottom: 20px;
	${tablet} {
		font-size: 48px;
		line-height: 54px;
	}
	${mobile} {
		font-size: 32px;
		line-height: 38px;
	}
`

export const SubHeader = styled.div`
	font-weight: 300;
	font-size: 26px;
	line-height: 30px;
	text-align: center;
	color: white;
	padding-bottom: 45px;
	max-width: 930px;
	margin: 0 auto;
	${mobile} {
		font-size: 24px;
		line-height: 30px;
	}
`
