import * as React from 'react'
import styled from 'styled-components'

import { lightBlue } from 'shared/colors'
import BottomImage from './bottom-image'
import { laptop } from 'shared/media-queries'
import { Header, SubHeader } from './shared-components'

const Content = styled.div`
	display: flex;
	color: white;
	overflow: hidden;
	${laptop} {
		flex-direction: column;
		max-width: 90%;
		margin: 0 auto;
	}
`
const Child = styled.div`
	flex: 0 0 50%;
	color: white;
`
const BottomChild = styled.div`
	flex: 0 0 50%;
	${laptop} {
		padding-top: 50px;
	}
`
const Text = styled.div`
	text-align: center;
	max-width: 700px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	${laptop} {
		max-width: none;
	}
`
const SubText = styled.div`
	font-size: 24px;
`
const Link = styled.span`
	cursor: pointer;
	color: ${lightBlue};
`

const BottomSection: React.FC = () => (
	<Content>
		<Child>
			<Text>
				<Header>Once you are logged into BuildingConnected</Header>
				<SubHeader>
					Select the <b>“Qualifications”</b> section and then click on the{' '}
					<b>“TradeTapp Invites”</b> tab
				</SubHeader>
				<SubHeader>
					Then you’re all set and it’s back to business as usual!
				</SubHeader>
				<SubText>Still have questions?</SubText>
				<SubText>
					<Link
						onClick={() =>
							window.open(
								'https://buildingconnected.zendesk.com/hc/en-us/articles/360050988774-TradeTapp-to-BuildingConnected-Migration-Subcontractor-FAQs-',
								'_blank'
							)
						}
					>
						Access our FAQs
					</Link>{' '}
					and{' '}
					<Link
						onClick={() =>
							window.open(
								'https://buildingconnected.zendesk.com/hc/en-us/articles/360050988814-Subcontractor-TradeTapp-to-BuildingConnected-Migration-Process-Overview-',
								'_blank'
							)
						}
					>
						Process Overview
					</Link>
				</SubText>
			</Text>
		</Child>
		<BottomChild>
			<BottomImage />
		</BottomChild>
	</Content>
)

export default BottomSection
