import * as React from 'react'
import styled from 'styled-components'

import TTLogo from 'imgs/ttlogo.svg'
import { ContentWrapper, Spacer } from 'views/components'
import { navy } from 'shared/colors'
import TopSection from './top-section'
import BottomSection from './bottom-section'
import { Header } from './shared-components'

const Container = styled.div`
	background-color: ${navy};
	height: 100%;
	min-height: 100vh;
`
const LogoContainer = styled.div`
	text-align: center;
	padding: 40px 0;
`

const AccountMigration: React.FC = () => (
	<Container>
		<div>
			<ContentWrapper>
				<LogoContainer>
					<TTLogo />
				</LogoContainer>
				<Header>TradeTapp is moving to BuildingConnected.com</Header>
			</ContentWrapper>
		</div>
		<TopSection />
		<Spacer size={50} />
		<BottomSection />
	</Container>
)

export default AccountMigration
