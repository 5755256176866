import * as React from 'react'
import styled from 'styled-components'

import { ContentWrapper, Spacer } from 'views/components'
import { Button } from 'views/components'
import { lightBlue } from 'shared/colors'
import { SubHeader } from './shared-components'
import { smallerThan } from 'shared/media-queries'

const Background = styled.div`
	background: linear-gradient(0deg, #23507c 0%, rgba(35, 80, 124, 0) 100%);
	padding-bottom: 50px;
`
const ButtonHeader = styled.div`
	font-weight: bold;
	font-size: 28px;
	color: white;
`
const ButtonsSection = styled.div`
	text-align: center;
`
const ButtonWrapper = styled.div`
	max-width: 400px;
	margin: 0 auto;
	& button {
		font-size: 16px;
		height: 65px;
	}
`
const BlueButton = styled(Button)`
	background-color: ${lightBlue};
	padding: 0 20px;
	width: 100%;
	height: 44px;
	border-radius: 2px;
	${smallerThan(386)} {
		&& {
			font-size: 12px;
		}
	}
`
const ClearButton = styled(Button)`
	background: none;
	border: 1px solid ${lightBlue};
	padding: 0 20px;
	width: 100%;
	height: 44px;
	border-radius: 2px;
	${smallerThan(386)} {
		&& {
			font-size: 12px;
		}
	}
`

const TopSection: React.FC = () => (
	<Background>
		<ContentWrapper>
			<SubHeader>
				You will need a BuildingConnected account to access your TradeTapp
				invites. TradeTapp accounts and BuildingConnected accounts are
				different.
			</SubHeader>
			<ButtonsSection>
				<ButtonHeader>Already have a BuildingConnected account?</ButtonHeader>
				<Spacer size={15} />
				<ButtonWrapper>
					<BlueButton
						label="I HAVE A BUILDINGCONNECTED ACCOUNT"
						onClick={() => {
							window.open(
								'https://app.buildingconnected.com/risk/vendor/invites',
								'_blank'
							)
						}}
					/>
				</ButtonWrapper>
				<Spacer size={45} />
				<ButtonHeader>Only have a TradeTapp account?</ButtonHeader>
				<Spacer size={15} />
				<ButtonWrapper>
					<ClearButton
						label="CREATE A BUILDINGCONNECTED ACCOUNT"
						onClick={() => {
							window.open(
								'https://app.buildingconnected.com/create-account',
								'_blank'
							)
						}}
					/>
				</ButtonWrapper>
			</ButtonsSection>
		</ContentWrapper>
	</Background>
)

export default TopSection
